import React from "react"
// import { PageProps } from "gatsby"
import classnames from "classnames"

import { useTranslation } from "@hooks"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import AppScreenshot from "@components/App/AppScreenshot"
import TattooStudio from "@components/App/TattooStudio"
import TattooArtist from "@components/App/TattooArtist"
import TattooCollector from "@components/App/TattooCollector"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Columns from "react-bulma-components/lib/components/columns"
import Card from "react-bulma-components/lib/components/card"
import Content from "react-bulma-components/lib/components/content"
import Heading from "react-bulma-components/lib/components/heading"

const App = () => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO
        title={t("pages.App.seo.title")}
        description={t("pages.App.seo.description")}
      />
      <Section>
        <Container>
          <Columns
            breakpoint="mobile"
            className={"is-reverse-mobile is-vcentered"}
          >
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <div className={"reverse"}></div>
              <Card className={"is-card-equal-height"} shadowless>
                <Card.Content>
                  <Content>
                    <Heading renderAs={"h1"}>
                      {t("pages.App.about.title")}
                    </Heading>
                    <p>{t("pages.App.about.description")}</p>
                  </Content>
                </Card.Content>
              </Card>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card className={"is-card-equal-height"}>
                <AppScreenshot />
              </Card>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section className={"has-background-black"}>
        <Container>
          <Columns breakpoint="mobile" className={"is-vcentered"}>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card
                className={classnames(
                  "is-card-equal-height",
                  "has-background-black"
                )}
              >
                <TattooCollector />
              </Card>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card
                className={classnames(
                  "is-card-equal-height",
                  "has-background-black"
                )}
                shadowless
              >
                <Card.Content>
                  <Content>
                    <Heading renderAs={"h3"} className={"has-text-white-bis"}>
                      {t("pages.App.collectors.title")}
                    </Heading>
                    <p className={"has-text-white-bis"}>
                      {t("pages.App.collectors.description")}
                    </p>
                  </Content>
                </Card.Content>
              </Card>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section>
        <Container>
          <Columns
            breakpoint="mobile"
            className={"is-reverse-mobile is-vcentered"}
          >
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card className={"is-card-equal-height"} shadowless>
                <Card.Content>
                  <Content>
                    <Heading renderAs={"h3"}>
                      {t("pages.App.artists.title")}
                    </Heading>
                    <p>{t("pages.App.artists.description")}</p>
                  </Content>
                </Card.Content>
              </Card>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card className={"is-card-equal-height"}>
                <TattooArtist />
              </Card>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section className={"has-background-black"}>
        <Container>
          <Columns breakpoint="mobile" className={"is-vcentered"}>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card
                className={classnames(
                  "is-card-equal-height",
                  "has-background-black"
                )}
              >
                <TattooStudio />
              </Card>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card
                className={classnames(
                  "is-card-equal-height",
                  "has-background-black"
                )}
                shadowless
              >
                <Card.Content>
                  <Content>
                    <Heading renderAs={"h3"} className={"has-text-white-bis"}>
                      {t("pages.App.studios.title")}
                    </Heading>
                    <p className={"has-text-white-bis"}>
                      {t("pages.App.studios.description")}
                    </p>
                  </Content>
                </Card.Content>
              </Card>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default App
