import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const TattooStudio = ({ alt, style, imgStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "tattoo-studio.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            aspectRatio
            # ...GatsbyImageSharpFluid // With blur-up
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.img.childImageSharp.fluid}
      style={style} /* styles for the wrapper container */
      imgStyle={imgStyle} /* Styles for the actual img element */
      alt={alt}
      className={"img--rounded"}
    />
  )
}

TattooStudio.defaultProps = {
  style: {},
  imgStyle: {},
  alt: "An image of a tattoo collector on IYNK",
}

TattooStudio.propTypes = {
  style: PropTypes.object.isRequired,
  imgStyle: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
}

export default TattooStudio
